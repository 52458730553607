h1 {
  text-align: right;
  color: white;
}

p {
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 900;
  color: #c00;
}

.post-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1vw;
}

.post {
  width: 26vw;
  margin: 1.5vw;
  padding: 1.5vw;
  min-height: 90%;
  background: white;
}

.image-wrapper {
  margin: 0;
  padding: 0;
  overflow: hidden;
  margin-bottom: 1em;
}

.image-wrapper img {
  width: 100%;
  height: 26vw;
  object-fit: cover;
}

.hashtag {
  color: #009ee0;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5vw;
}

.title-wrapper img {
  width: 400px;
}
